<template>
  <div class="common-bg page-container">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field v-model="merchant_name" required minlength="2" maxlength="10" label="商户名称" />
        <van-field v-model="phone_number" type="tel" maxlength="11" required name="pattern" label="手机号码" />
        <!-- :rules="[{ pattern }]" -->
      </van-cell-group>
      <van-cell-group inset class="shop-address">
        <div style="line-height: 200px">商户地址</div>
        <div>
          <van-field input-align="center" v-model="merchant_address_sheng" placeholder="省" />
          <van-field input-align="center" v-model="merchant_address_shi" placeholder="市" />
          <van-field input-align="center" v-model="merchant_address_qu" placeholder="区/县" />
          <van-field input-align="center" v-model="merchant_address_dizhi" placeholder="详细" />
        </div>
      </van-cell-group>
      <van-cell-group inset class="shop-address">
        <div style="line-height: 200px">店主身份证</div>
        <div class="id-card">
          <div class="df aic jcc">
            <p>正面</p>
            <van-field name="id_card_positive">
              <template #input>
                <van-uploader :after-read="afterRead1" :before-read="beforeRead" v-model="id_card_positive"
                  :max-count="1" />
              </template>
            </van-field>
          </div>
          <div class="df aic jcc">
            <p style="weight: 50px">反面</p>
            <van-field name="uploader">
              <template #input>
                <van-uploader :after-read="afterRead2" :before-read="beforeRead" v-model="id_card_side"
                  :max-count="1" />
              </template>
            </van-field>
          </div>
        </div>
      </van-cell-group>
      <van-cell-group inset class="shop-address">
        <div style="line-height: 200px">营业执照</div>
        <div class="id-card">
          <div class="df aic jcc">
            <p>正面</p>
            <van-field name="id_card_positive">
              <template #input>
                <van-uploader :before-read="beforeRead" :after-read="afterRead3" v-model="license_positive"
                  :max-count="1" />
              </template>
            </van-field>
          </div>
          <div class="df aic jcc">
            <p style="weight: 50px">反面</p>
            <van-field name="uploader">
              <template #input>
                <van-uploader :before-read="beforeRead" :after-read="afterRead4" v-model="license_side"
                  :max-count="1" />
              </template>
            </van-field>
          </div>
        </div>
      </van-cell-group>
    </van-form>
    <van-button type="primary" size="normal" @click="onSubmit">提交</van-button>
  </div>
</template>
<script>
import { getShopMsg, upload } from "@/utils/api.js";
import { getToken } from "@/utils/auth";
import { Toast } from 'vant';
export default {
  data() {
    return {
      // pattern: /1[3-9]\\d{9}/, //手机号验证规则
      token: "",
      title: "我要开店",
      show1: false,
      password: "",
      form: {},
      merchant_name: "", //店铺名称
      phone_number: "", //电话号码
      merchant_address_sheng: "", //省
      merchant_address_shi: "", //市
      merchant_address_qu: "", //区
      merchant_address_dizhi: "", //详细地址
      id_card_positive: [], //身份证正面
      id_card_side: [], //身份证反面
      license_positive: [], //营业照正面
      license_side: [], //营业照反面
    };
  },
  methods: {
    onSubmit() {
      const id_card_positive = this.img1
      const id_card_side = this.img2
      const license_positive = this.img3
      const license_side = this.img4
      const data = {
        merchant_name: this.merchant_name,
        phone_number: this.phone_number,
        merchant_address_sheng: this.merchant_address_sheng,
        merchant_address_shi: this.merchant_address_shi,
        merchant_address_qu: this.merchant_address_qu,
        merchant_address_dizhi: this.merchant_address_dizhi,
        id_card_positive: this.img1,
        id_card_side: this.img2,
        license_positive: this.img3,
        license_side: this.img4
      }
      getShopMsg(data).then(async (res) => {
        if (res.code === 200) {
          await Toast.success('添加店铺成功');
          this.$router.push("/user")
        }
      });
    },

    beforeRead(file) {
      if (
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/png"
      ) {
        this.$toast("请上传jpg或png格式的图片");
        return false;
      }
      return true;
    },
    afterRead1(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast("上传成功");
          this.img1 = res.data;
        } else {
          this.$toast("上传失败");
        }
      });
    },
    afterRead2(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast("上传成功");
          this.img2 = res.data;
        } else {
          this.$toast("上传失败");
        }
      });
    },

    afterRead3(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast("上传成功");
          this.img3 = res.data;
        } else {
          this.$toast("上传失败");
        }
      });
    },
    afterRead4(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast("上传成功");
          this.img4 = res.data;
        } else {
          this.$toast("上传失败");
        }
      });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
  created() {
    this.token = getToken();
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  ::v-deep .van-field__control {
    text-align: right;
  }
}

.van-cell-group {
  margin-bottom: 20px;
}

.shop-address {
  display: flex;
  justify-content: space-around;
  align-content: center;

  .van-field {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
}

.id-card {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  p {
    display: inline-block;
    width: 50px;
  }
}

.van-button {
  width: 200px;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  display: inherit;
}
</style>
